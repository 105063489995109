@import './../../CSSLibrary/UXCVariables.css';

.uxcpagetemplate-utilityscreen-headingwrap {
    text-align:left;
}

.uxcpagetemplate-utilityscreen-container {
    border-radius:4px;
    box-shadow:0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    margin-top:20px;
    padding:20px;
}