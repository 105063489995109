.alert {
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    font-weight: bold;
  }
  
  .alert.success {
    background-color: #d4edda;
    color: #155724;
  }
  
  .alert.error {
    background-color: #f8d7da;
    color: #721c24;
  }

  .uxcpagetemplate-utilityscreen-container .MuiTextField-root, .uxcpagetemplate-utilityscreen-container .MuiButton-root {
    margin-bottom:14px;
}