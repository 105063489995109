.uxc-screen-step-block {
    width:80px;
    height:60px;
    border-radius:100px;
    padding-top:20px;
    font-size:30px;
    border-width: 2px; /* Set the border width as needed */
    border-style: solid; /* Set the border style to solid */
    border-image: linear-gradient(to bottom, #0339A7, #0497F2); /* Create a vertical gradient border */
    border-image-slice: 1; /* Ensure the entire border is filled with the gradient */
    color:#0339A7;
}

.uxc-page-workinux-min-card-height .uxc-comp-card-lower {
    min-height:135px;
}