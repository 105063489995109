.uxc-comp-marquee-wrapper {
    background:#FFFFFF;
    padding-top:40px;
    padding-bottom:40px;
    text-align: left;
    border-bottom:1px solid #0339A6;
}
.uxc-comp-marquee-wrapper h1 {
    font-size:2.75rem;
    line-height:1.18181818;
    margin:0;
}
.uxc-comp-marquee-wrapper p {
    font-size:1.25rem;
    line-height:1.4;
    margin:0;
}
.uxc-comp-marquee-wrapper button {
    margin-top:8px !important;
    background-color:white !important;
}
