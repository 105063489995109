/* Debug class */
.uxc-debug {
    background-color:#f1f1f1;
    font-size:14px;
    color:darkgray;
}
.uxc-debug::before {
    content:"[DEBUG] "
}

.uxc-font-body {
    font-size:20px;
}
.uxc-align-left {
    text-align: left;
}