.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Typography */
h3 {
  font-size:1.5rem;
    line-height:1.33333333;
    font-weight:500;
    margin-top:0px !important;
}

.uxc-typo-color-white {
  color:white;
}

/* --- Global Utilities --- */
.uxc-global-utility-text-left {
  text-align: left;
}

/* padding and margin */
.uxc-global-margin-bottom-1x {
  margin-bottom:8px;
}

.uxc-global-margin-bottom-2x {
  margin-bottom:16px;
}

.uxc-global-margin-bottom-3x {
  margin-bottom:24px;
}

.uxc-global-margin-bottom-4x {
  margin-bottom:32px;
}
