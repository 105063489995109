/* variables.css */
@import './UXCComponentButton.css';
@import './UXCComponentUtilities.css';

:root {
    /* Colors */
    --uxc-color-blue-dark: #0339A6;
    --uxc-color-blue-med: #056CF2;
    --uxc-color-blue-light: #0597F2;
    --uxc-color-blue-teal: #03738C;

    /* Padding */
    --padding-lg: 18px;

    /* -- Patterns -- */

  }