.uxc-marketing-training-sidepanel-outer {
    border-left:#0339A7 1px solid;
    padding-left:20px !important;
    padding-right:20px !important;
}
.uxc-page-marketing-training-marquee-wrap > div {
    margin-bottom:40px;
    -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}
.ui-marketing-marquee-bg-pega-ui {
    background:url('../Images/Marketing/uxi-marketing-pegaui-back.png');
    background-size:cover;
}

.ui-marketing-marquee-bg-pega-ui div {
    background:white;
    padding:20px;
}