@import './../../CSSLibrary/UXCVariables.css';

.uxc-comp-header-outer {
    background-color: #FFFFFF !important;
}
.uxc-comp-header-outer img {
    height:40px;
    width:auto;
}
.uxc-comp-header-outer svg {
    fill:var(--uxc-color-blue-dark) !important;
}
.uxc-comp-header-button {
    background-color:#fff !important;
    color:var(--uxc-color-blue-dark) !important;
}
.uxc-comp-header-button:hover {
    background-color:#fff !important;
    color:var(--uxc-color-blue-med) !important;
}
@media (min-width: 720px) {
    /* Styles! */
    .uxc-comp-header-menu-button, .uxc-comp-header-mobile-menu-wrap {
        display:none !important;
    }
}
@media (max-width: 720px) {
    /* Styles! */
    .uxc-comp-header-desktop {
        display:none !important;
    }
}
.uxc-comp-header-mobile-menu-wrap .MuiSvgIcon-root path {
    fill: var(--uxc-color-blue-dark) !important;
}
.uxc-comp-header-mobile-menu-wrap .MuiListItemText-root span {
    color: var(--uxc-color-blue-dark) !important;
}

.uxc-comp-header-inactive-item {
    display:none !important;
}
